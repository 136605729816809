@import '_page_inspiration';
@import '_page_inspirations';
@import '_page_about';
@import '_page_activeties';
@import '_page_activity';
@import '_page_faq';

.page__banner {
    margin-bottom: unset!important;
}

.page__content {
    .row {
        padding: 1.5rem 0;
    }

    padding-bottom: $row-spacing - 2rem;

    .imageandtext {
        img {
            max-height: 420px;
        }
    }

}