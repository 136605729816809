.page__faq {
    h2,p {
        margin:unset!important;
    }

    .accordion {
        h2,p {
            font-family: "Roboto", sans-serif !important;
        }
    }
}