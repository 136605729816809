.component {
    &:nth-child(odd) {
        background-color: $component-bg-color;
    }

    &__heading {

        margin-bottom:1rem;

        h1,h2,h3 {
            margin:unset!important;
        }
        //margin-bottom:$sm-spacing;
    }


}