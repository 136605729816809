.relateditems {
    .container {
        max-width:1000px!important;
    }

    .campaign {
        padding: 15px!important;
    }

    &__item {
        padding: $lg-spacing;
        background:white;
        border-radius:5px;
        margin:$sm-spacing;
        @extend .box-shadow;
    }
}