// MAIN
// https://www.color-hex.com/color/01738e


// TYPOGRAPHY
$main-header-color: #1d1d1d;
$secondary-content-color:#292929;
$third-label-color:#697076; 


$main-bg-color: #01738e;
$banner-bg-color: $main-header-color;
$footer-bg-color: #1d1d1d;
$box-main-bg-dark: $main-header-color;
$main-button-bg-color:$main-header-color;


// BORDER
$main-border-color: #a9adb1;

// BACKGROUND


// COMPONENT
$component-bg-color: #f5f8fb;//#f8f8f8;

// BUTTON
$button-action-bg-color: #ffa200;
$button-primary-bg-color: #316f7a;


.family-and-friends {
    background-color:#87a4c1!important;
}

.business-and-pleasure {
    background-color: #666688!important;
}

.bike-and-hike {
    background-color: #668866!important;
}

.captains-choice {
    background-color: #f9ad1c!important;
}