$xs-spacing: 0.5rem;
$sm-spacing: 1rem;
$md-spacing: 1.5rem;
$lg-spacing: 2.25rem;
$xl-spacing: 3.375rem;
$xxl-spacing: 5.063rem;
$xxxl-spacing: 7.594rem;

$row-spacing: $lg-spacing*2.2;

$img-max-height: 390px;