.back-to-top-btn{
    position: fixed; /* Fixed/sticky position */
    bottom: 32px; /* Place the button at the bottom of the page */
    right: 32px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    display: none;
    height: 66px;
    width: 66px;
    line-height: 66px;
    border-radius: 50%;
    text-align: center;
    background-color: white;

    font-weight: 400;


    transition: 0.4s all;
    border: 2px solid $banner-bg-color;

    &__icon {
        margin-top:-6px;
    }

    span {
        display: block;
        color: $banner-bg-color;
        transition: 0.4s all;
        font-size: 1.1rem;
    
    }
}