.list {
    text-align: left;

    ul {
        columns: 2;

        @include tablet(){
            columns: 1;
        }
    }
    li {
        break-inside: avoid-column;
        padding:$xs-spacing;
    }
}