.videoandtext {
    display:flex;
    align-items: center;

    .videoandtext__text {
   
        border:1px solid $box-main-bg-dark;

        background:$box-main-bg-dark;
        padding:30px;
        z-index:1;

        h1,h2,p {
            color:white!important;
            font-size:2rem!important;
            margin:unset!important;
            padding:unset!important;
        }

        @include largescreen(){
            margin-right:-140px;



            .button a {
                color:black;
                border-color: black;
            }
        }
    }

    .videoandtext__video {
        padding:unset!important;

        .embed-responsive-item {
            width:100%;
            height:500px;
        }
    }
}
