header {
    @include mediumscreen() {
        .container {
            max-width: 100%;
        }
    }

    .flag {
        &:hover {
            text-decoration: none;
        }

        img {
            width: 35px;
            margin-left: 50px;
        }
    }

    .navbar {
        background: white !important;

        @include mobile(){
            .flag {
                img {
                    margin-top:10px!important;
                    margin-left:unset!important;
                }
            }
        }

        .nav-link {
            font-weight: 400;
            letter-spacing: 2;
            padding: unset !important;
            margin: 0 1rem 0 1rem;
            border-bottom: 2px solid transparent;

            @include mobile(){
                padding:5px!important;
                padding-left:unset!important;
                margin-left:unset!important;

            }

            &:hover {
                border-bottom: 2px solid black;
            }
        }

        .nav-link.active {
            border-bottom: 2px solid black;
            font-weight: 500;
        }

        
    }


}

footer {

    background:$footer-bg-color!important;
    color:white!important;
    .services {
        a {
            color: white !important;
            text-decoration: none;
            border-bottom: 1px solid $footer-bg-color;

            &:hover {
                border-bottom: 1px solid white!important;
            }
        }

        p {

            &:before {
                @include font-awsome("\f105")
            }
        }
    }

    .icon-social {
        color:white!important;
        text-decoration: none;
        padding-left:15px;
        
        svg, img {
            width:30px;
            height:30px;
            color:white!important;
        }
    }
}