@import "../mixin/font-awesome";

.wideheroimage {
    position: relative;
    .component {
        .container.image-block {
            max-width: 1350px!important;
        }
    }
    .wideheroimage__image {
        max-height: 700px;

        img {
            height: 100%;
            object-fit: cover;

            @include mobile(){
                height:500px;
            }
        } 
    }

    .wideheroimage__item {
        position: relative;
    }

    .container__text {
        position:relative;

        @include tablet(){
            background-color: #05694c;
        }

        .wideheroimage__text {
            position:absolute; 
            bottom:25px; 
            padding:$lg-spacing;
            background-color: #05694c;

            p {
                margin:unset;
            }

            @include mediumscreen(){
                bottom:0;
            }
    
            @include tablet() {
                position:unset!important;
                padding:16px;
                margin-top:10px;
           
            }


            text-align:left;
            width:90%;
    
            p { 
                color:white!important;
                font-size:1.5rem!important;
                font-family: 'Roboto'!important;
                font-weight: 300;
            
            }
            h1,h2,h3 {
                color:white!important;
                font-family: 'Roboto'!important;
         
            }

            .wideheroimage__description {
                max-width: 800px;
            }
        }  
    }

    .information {

        .container {
            max-width:1320px;
        }

        @include largescreen() {
            position:absolute;
            bottom:-80px;
            width:100%;

        }

        &__item:first-child {
            background:#1d1d1d;
        }

        &__item {

            background-color: black;

            h2,a {
                color:white;
            }
            color:white;



            @include largescreen() {
                .button {
                    text-align: right;
                }   
            }
        }

        &__text {

            &--medium {
                font-size:1.2rem!important; 
            }

            &--big {
                font-size:1.5rem!important;
            }
        }

        
    }
}