.campaign {

    @include mediumscreen() {
        .col-lg-4:not(:last-child) {
            margin-bottom: $md-spacing;
        }
    }

    &__item {
        background-color: #ffffff;
        height: 100%;
        border: 1px solid $main-border-color;
        border-radius: 5px;

        img {
            height: 320px!important;
            object-fit: cover;
        }
    }

    &__content {
        padding: $md-spacing;

        p {
            margin-bottom: unset;
        }
    }

    #duration {

        margin-right:20px;
    

        &:before {
            padding-right:5px;
            font-family: "Font Awesome 5 Pro";

            font-weight: 100;
            content: '\f017';
        }

    }

    #persons {
        margin-right:20px;

        &:before {
            padding-right:5px;
            font-family: "Font Awesome 5 Pro";
 
            font-weight: 100;
            content: '\f007';
        }

    }
}