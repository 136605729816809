.heroimage {
    margin: auto;

    .container {
        max-width: 1500px!important;

        .row {
            display: flex;
            align-items: center;

            @include mediumscreen() {
                padding: unset;

                .heroimage__img {
                    padding: unset;
                }

                .heroimage__text {
                    padding: $xl-spacing;
                }
            }
        }
    }

    .heroimage__img  {

        img {
            max-height: 600px;
            object-fit: cover;
        }
        div {
            max-width: 1600px;
            min-height: 270px;
            margin: auto;

        }
    }

    .flex-row-reverse {
        .heroimage__text {

            @include largescreen() {
                margin-right: -70px!important;
                margin-lefT:unset!important;
            }

        }
    }

    .heroimage__text {
        padding: $sm-spacing;

        @include largescreen() {
            background: white;
            padding: $lg-spacing!important;
            margin-left: -70px;
            z-index: 1;
        }

        @include mobile() {
            padding: $md-spacing!important;
        }
     
        
    }

    .info-links {
        @include mobile(){
            p {margin:unset;}
            a {
                width:100%;
                display:inline-block;
                padding-left:unset!important;
                padding-top:20px!important;

                i {
                    display:none;
                }
            }
        }
    }

    #duration {

        margin-right:20px;
    

        &:before {
            padding-right:5px;
            font-family: "Font Awesome 5 Pro";

            font-weight: 100;
            content: '\f017';
        }

    }

    #persons {
        margin-right:20px;

        &:before {
            padding-right:5px;
            font-family: "Font Awesome 5 Pro";
 
            font-weight: 100;
            content: '\f007';
        }

    }
}
