@import 'bootstrap/scss/bootstrap';

.container {
    max-width: 1250px!important;
}

.row {
    padding:$lg-spacing*1.5 0 $lg-spacing*1.5 0;

    @include mobile(){
        padding:$lg-spacing*2 0 $lg-spacing*2 0; 
    }
}

.navbar-light .navbar-nav .nav-link {
    color: $main-header-color!important;
}

.btn {
    padding-top:$sm-spacing;
    padding-bottom:$sm-spacing;

    color:$main-header-color!important;
    border:1px solid $main-button-bg-color;

    padding:$xs-spacing $sm-spacing;
    text-decoration: none;
    border-radius:40px;

    &--action {
        color: white!important;
        border-color: $button-action-bg-color!important;
        background: $button-action-bg-color!important;
        opacity:0.9;
        
        &:hover {
            opacity:1;
        }
    }
}

blockquote {
    p{
        &:last-of-type {
            margin-bottom: unset!important;
        }
    }
}

.badge {
    background:  $main-header-color;
    margin-bottom:5px;
}

