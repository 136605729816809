.itemshrefimages {

    &__item {
        position: relative;
    }

    &__image {
        height:100%;

        img {
            max-height: $img-max-height;
            min-height: 300px;
            height:100%;
            object-fit: cover;

            @extend .shadow-sm;
            @extend .rounded
        }
    }

    &__text {
        position:absolute; 
        bottom: 0;
        padding:40px;
        text-align:center;
        width:100%;

        h1,h2,h3 {
            color:white!important;
            font-size:1.7rem!important;
            font-family: 'Roboto'!important;
            background-color: #05694c;

            &:after {
                font-size:1.7rem!important;
                @include font-awsome("\f061")
            }
        }
    }

}