.top3activeties {

    @include mediumscreen() {
        .col-lg-4:not(:last-child) {
            margin-bottom: $md-spacing;
        }
    }

    &__item {
        background-color: #ffffff;
        height: 100%;
        border: 1px solid $main-border-color;
        border-radius: 5px;

        img {
            height: $img-max-height;
            object-fit: cover;
        }
    }

    &__content {
        padding: $md-spacing;

        p {
            margin-bottom: $md-spacing;
        }
    }
}