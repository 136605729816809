.imageandtext {
    display:flex;
    align-items: center;

    &__content {
        padding: 0 $xl-spacing!important;
    }

    img {

        @extend .rounded;



        max-height: $img-max-height;

        object-fit: contain;
    }
}