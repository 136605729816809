.button {
    padding-top:$sm-spacing;
    padding-bottom:$sm-spacing;

 

    a {
        color:$main-header-color!important;
        border:1px solid $main-button-bg-color;

        padding:$xs-spacing $sm-spacing;
        text-decoration: none;
    
        &:hover {
            background-color: $main-button-bg-color;
            color:white!important;
        }

    }

    &--action {
        a {
            color: white!important;
            border-color: $button-action-bg-color!important;
            background: $button-action-bg-color!important;
            opacity:0.9;
            font-size:1.4rem;
            
            &:hover {
                opacity:1;
            }

            &:after {
                @include font-awsome("\f105")
            }
        }

    }

    &--white {

        a {
            color:black!important;
            border-color: white!important;
            background:white!important;
            opacity:0.9;

            &:hover {
                color:black!important;
                opacity:1;
            }
        }
    }

    &--component {
        
        a {
            color: $main-header-color;
            border-color: $main-header-color;


            
            border-radius: 40px;
            opacity:0.9;
            padding:$xs-spacing $md-spacing;
            
            &:hover {
                opacity:1;
            }

            &:after {
                @include font-awsome("\f105")
            }
        } 
    }

}