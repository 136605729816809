.page__activeties {
    .campaign {
        padding:15px;
    }

    .page__content {
        padding-top:$row-spacing;
    }

    .categories {
        .row {
            max-width:1010px!important;
            margin:auto;
        }
    }
}