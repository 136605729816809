$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-x-large: 1200px;
$breakpoint-2x-large: 1400px;

@mixin largescreen {
    @media (min-width: $breakpoint-x-large) {
        @content;
    }
}

@mixin mediumscreen {
    @media (max-width: breakpoint-max(xl, $grid-breakpoints)) {
        /* <= 991 */
        @content;
    }
}

@mixin tablet {
    @media (max-width: $breakpoint-large) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $breakpoint-small) {
        @content;
    }
}
