.frontpageheroimage {
    .container {

        h1 {
            font-weight: 700!important;
            font-size:2.5rem!important;
        }
    }
    .image {
        img {
            max-height: 500px;
            object-fit: cover;
        }
    }
}