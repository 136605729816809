@import "_colors";

// https://type-scale.com/
@import url('https://fonts.googleapis.com/css?family=Roboto:500,400,300');
@import url('https://fonts.googleapis.com/css?family=Manrope:500,400,300');
@import url('https://fonts.googleapis.com/css?family=Tinos:400');
@import url('https://fonts.googleapis.com/css?family=Pontano+Sans:400');

html {
  font-size: 16px;
  box-sizing: border-box;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 18px;
  }
} 

body {
  background: white!important;
  font-family: 'Manrope', sans-serif!important;
  color: $secondary-content-color;
  text-shadow:0 0 1px transparent;
  text-rendering: optimizeSpeed;
  line-height: 1.6!important;
  font-weight: 300!important;
}

p {margin-bottom: 1rem;}

h1, h2, h3, h4, h5 {
  font-size-adjust: 0.5;
  text-shadow:0 0 1px transparent;
  font-family: 'Manrope', sans-serif!important;
  color: $main-header-color!important;
}

h1 {
  margin-top: 0;
  font-size: 2rem!important;
}

h2 {font-size: 1.702rem!important;}

h3 {font-size: 1.566rem!important;}

h4 {font-size: 1.224rem!important;}

h5 {font-size: 1.125rem!important;}

small, .text_small {font-size: 0.889rem!important;}

p, a {font-size: 1.1rem!important;}
