.categories {
    h2 {
        font-size: 1.2rem!important;

        &:after {
            margin-left:6px;
            @include font-awsome("\f105")
        }
    }

    a {
        text-decoration: none!important;
    }
}