// VARIABLES
@import "base/_variables.scss";

// TYPOGRAPHY
@import "base/_typography.scss";

// COLORS
@import "base/_colors.scss";

// MIXIN
@import "mixin/mixin";

// BASE
@import "base/bootstrap-overrides";

// LAYOUT
@import "layout/layout";

@import "components/components";
@import "pages/pages";

img {
    width:100%;
}

blockquote {
    padding:$sm-spacing;
    border-left: 2px solid black;
}